import React from 'react'
import CarouselComp from 'react-multi-carousel'
import styled from '@emotion/styled'
import 'react-multi-carousel/lib/styles.css'
import { Button } from '../../atoms'
import { SieArrow } from '../..'
export const Carousel: React.FC = ({ children }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
  }

  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest
    return (
      <Button
        asCircle
        icon={SieArrow}
        onClick={() => onClick()}
        style={{ position: 'absolute', right: 0 }}
      ></Button>
    )
  }

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest
    return (
      <Button
        asCircle
        icon={SieArrow}
        onClick={() => onClick()}
        style={{ position: 'absolute', left: 0, transform: 'rotate(-180deg)' }}
      ></Button>
    )
  }
  return (
    <CarouselOuter>
      <CarouselHolder
        partialVisible={true}
        responsive={responsive}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {children}
      </CarouselHolder>
    </CarouselOuter>
  )
}
const CarouselOuter = styled.div(
  ({ theme: { grid } }) => `
`
)

const CarouselHolder = styled(CarouselComp)(
  ({ theme: { grid } }) => `
  & ul > li > div {
    padding: 0 1vw;
  }
`
)
