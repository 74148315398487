import styled from '@emotion/styled'
import { AtomsProvider, Chat, ChatProps } from 'cuenect-web-core'
import React from 'react'
import { Button } from '../../atoms/button'
import { Input } from '../../atoms/input'
import { SiemensChatMessage } from '../../atoms/siemensChatMessage/siemensChatMessage'

export const SiemensChat: React.FC<ChatProps> = props => {
  return (
    <AtomsProvider customAtoms={{ ChatMessage: SiemensChatMessage, Button }}>
      <InputStyles>
        <Chat {...props}></Chat>
      </InputStyles>
    </AtomsProvider>
  )
}

const InputStyles = styled.div(({ theme }) => ({
  '& input': {
    borderRadius: 0,
    border: 0,
    color: theme.colors.textDark,
    boxShadow: 'none',
    fontSize: '1rem',

    '&:focus': {
      boxShadow: 'none',
    },
  },
}))
