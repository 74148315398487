import styled from '@emotion/styled'
import React from 'react'
import { mq } from './../../../utility'

export interface CognisphereProps {
  visual: string
  asImage?: boolean | undefined
  fullscreen?: boolean | undefined
}

export interface CognisphereVideoProps
  extends Pick<CognisphereProps, 'fullscreen'> {}

interface BgImageProps extends Pick<CognisphereProps, 'visual'> {}
export const Cognisphere: React.FC<CognisphereProps> = ({
  visual,
  asImage,
  fullscreen,
}) => {
  return (
    <CognisphereContainer>
      {asImage && <BgImage visual={visual} />}
      {!asImage && (
        <BgVideo fullscreen={fullscreen}>
          <video
            data-matomo-ignore
            loop
            muted
            autoPlay
            playsInline
            className="fullscreen-bg__video"
          >
            <source src={visual} type="video/mp4" />
          </video>
        </BgVideo>
      )}
    </CognisphereContainer>
  )
}
const CognisphereContainer = styled.div`
  z-index: -1;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
`

const BgVideo = styled.div<CognisphereVideoProps>(({ fullscreen }) => ({
  position: 'absolute',
  left: 0,
  right: '-50vw',
  [mq[3]]: {
    left: '0%',
    right: '0%',
  },
  top: 0,
  bottom: 0,
  video: {
    background: 'transparent',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    width: '100%',
    transition: 'height .5s',
    height: '100vh',
    bottom: `${fullscreen ? '0' : '-25vh'}`,
    objectFit: 'cover',
    objectPosition: 'right center',

    display: 'block',
    [mq[3]]: {
      objectFit: 'cover',
      top: 0,
      bottom: 'inherit',
      height: '100%',
      objectPosition: `${fullscreen ? 'center' : '30%'} center`,
    },
  },
}))

const BgImage = styled.div<BgImageProps>(
  ({ visual }) => `
width:100%;
height:100%;

background: url(${visual}) no-repeat right center;
background-size:cover;

`
)
