import styled from '@emotion/styled'
import { AtomsProvider, ChatMessageProps } from 'cuenect-web-core'
import React from 'react'
import { Button } from '../button'
import { Heading } from '../heading'
import { Text } from '../text'

const ADMIN_USERNAMES = ['Siemens', 'Siemens Cybersecurity']

export const SiemensChatMessage: React.FC<ChatMessageProps> = ({
  message,
  highlightMessage,
  messageOwner,
  pending,
  rejectedReason,
  userIsMessageOwner,
}) => {
  return (
    <ChatMessageContainer
      highlight={ADMIN_USERNAMES.includes(messageOwner)}
      pending={pending}
    >
      <UsernameContainer>
        <MessageOwnerText>{messageOwner}</MessageOwnerText>
      </UsernameContainer>

      <MessageContainer>
        <Text>{message}</Text>
        {rejectedReason && <ErrorText>{rejectedReason}</ErrorText>}
      </MessageContainer>
    </ChatMessageContainer>
  )
}

interface ChatMessageContainerProps {
  highlight?: boolean
  pending?: boolean
}

const ErrorText = styled(Text)(({ theme }) => ({
  color: `${theme.colors.error} !important`,
}))

const MessageOwnerText = styled(Text)({
  fontSize: '1rem',
  fontWeight: 'bold',
})

const ChatMessageContainer = styled.div<ChatMessageContainerProps>(
  ({ theme, highlight, pending }) => ({
    backgroundColor: highlight ? theme.colors.text : '#003351',
    padding: '1.5rem',
    opacity: pending ? '.5' : '1.0',

    '& p, h3': {
      color: highlight ? theme.colors.bgColor : theme.colors.text,
    },
  })
)

const UsernameContainer = styled.div({})

const MessageContainer = styled.div({})
