import styled from '@emotion/styled'
import React, { useEffect, useRef } from 'react'

import { LoadingIndicator } from '../../atoms'

interface ExternalContentProps {
  uri: string
  fullscreen?: boolean
}

interface ExternalContentContainerProps {
  fullscreen?: boolean
  loading?: boolean
}

export const ExternalContent: React.FC<ExternalContentProps> = ({
  uri,
  fullscreen,
}) => {
  const [loading, setLoading] = React.useState(true)

  return (
    <ExternalContentContainer loading={loading} fullscreen={fullscreen}>
      {loading && <LoadingIndicator />}
      <iframe
        allowFullScreen
        src={uri}
        frameBorder="0"
        onLoad={() => setLoading(false)}
      ></iframe>
    </ExternalContentContainer>
  )
}

const ExternalContentContainer = styled.div<ExternalContentContainerProps>(
  ({ theme: { colors, grid }, loading, fullscreen }) => `
  width:${fullscreen ? '90vw' : '100%'};
  height:${fullscreen ? '90vh' : '100%'};
  display:flex;
  align-items:center;
  justify-content:center;
  iframe{
    opacity:${loading ? 0 : 1};
    width:${loading ? 0 : 100}%;
    height:100%;
  }

`
)
