import React from 'react'
import { ServiceContainer } from 'react-service-container'
import {
  AuthenticationContextProvider,
  SlideInContextProvider,
  BookmarksContextProvider,
  ReservationContextProvider,
  ModalContextProvider,
  VodContextProvider,
  FullscreenContextProvider,
  TimezoneContextProvider,
  GlobalContextProvider,
} from './src/utility'
import { featureFlags, isHidePage } from './src/config'
import { FeatureFlagProvider } from 'cuenect-web-core'

const uri = () => {
  const lang =
    typeof window !== 'undefined' ? window.location.pathname.split('/')[1] : ''
  if (lang === 'de') {
    return 'https://new.siemens.com/de/de/unternehmen/themenfelder/cybersecurity.html'
  }

  return 'https://new.siemens.com/global/en/company/topic-areas/cybersecurity.html'
}

export const onClientEntry = () => {
  if (typeof window !== 'undefined' && isHidePage()) {
    window.location.replace(uri())
  }
}

export const wrapRootElement = ({ element }) => (
  <GlobalContextProvider>
    <FeatureFlagProvider featureFlags={featureFlags}>
      <FullscreenContextProvider>
        <TimezoneContextProvider>
          <AuthenticationContextProvider>
            <SlideInContextProvider>
              <ModalContextProvider>
                <VodContextProvider>
                  <BookmarksContextProvider>
                    <ReservationContextProvider>
                      {element}
                    </ReservationContextProvider>
                  </BookmarksContextProvider>
                </VodContextProvider>
              </ModalContextProvider>
            </SlideInContextProvider>
          </AuthenticationContextProvider>
        </TimezoneContextProvider>
      </FullscreenContextProvider>
    </FeatureFlagProvider>
  </GlobalContextProvider>
)
