import styled from '@emotion/styled'
import { TimeUtil } from 'cuenect-web-core'
import { Player } from 'furioos-sdk'
import React from 'react'
import { Button } from '../button'

const USER_MAX_TIMEOUT = 10800000 // 3h
const inter = null

const safelyParseJSON = (json: string): unknown => {
  let parsed: unknown
  try {
    parsed = JSON.parse(json)
  } catch (e) {
    console.log('ERROR', e)
    parsed = {}
  }

  return parsed
}

interface FurioosProps {
  playerId: string
}

export type SDKMessage = Record<string, string | string[]>

export interface FurioosPlayer {
  sendSDKMessage(sdkMessage: SDKMessage): void
  onSDKMessage(callback: (sdkMessage: string) => void): void
  onAppStart(callback: () => void): void
  onStreamStart(callback: () => void): void
}

export const Furioos = React.forwardRef<FurioosPlayer, FurioosProps>(
  ({ playerId }, ref) => {
    const options = {
      whiteLabel: true,
      hideToolbar: true,
      hideTitle: true,
      hidePlayButton: false,
      // debugAppMode: true
    }

    // const test = React.useRef<FurioosPlayer>()

    React.useEffect(() => {
      const newPlayer = new Player(playerId, 'furioos-container', options)

      // if (test.current) {
      //   console.log('test.current', test.current)

      //   test.current?.onSDKMessage(m => console.log('got message', m))
      // }

      if (!ref) {
        return
      }

      if (typeof ref === 'function') {
        ref(newPlayer)

        return
      }

      ref.current = newPlayer
    }, [])

    return <FurioosContainer id="furioos-container"></FurioosContainer>
  }
)

const DebugContainer = styled.div`
  button {
    display: inline-block;
    margin:20px:
  }
`
const FurioosContainer = styled.div(
  ({ theme: { colors, grid } }) => `
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  iframe{
    border:0;
    width:100%;
    height:100%;
  }
`
)

const FurioosContainerDetection = styled.div(
  ({ theme: { colors, grid } }) => `
  position:fixed;
  pointer:none;
  background:red;
  opacity:.5;
  width:100vw;
  height:100vh;

    pointer-events:auto;


`
)
