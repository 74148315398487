import styled from '@emotion/styled'
import {
  ChatHistoryItem,
  ChatMessageSocket,
  isLivestreamSimulatorState,
  LiveStreamSimulator,
  LiveStreamSimulatorRef,
  LivestreamSimulatorState,
  LivestreamSimulatorStateType,
  LiveVideo,
  StateId,
  TimeUtil,
  useChatClient,
  useDimensions,
} from 'cuenect-web-core'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AuthService } from '../../../api/auth'
import {
  ProgramCategory,
  ProgramEvent,
  ProgramService,
} from '../../../api/program'
import { Paragraph } from '../../atoms'
import {
  Button,
  Heading,
  IProgramEntry,
  ProgramEntry,
  SiemensChat,
  Video,
} from './../../'
import { eventConfig, isPostEventFirst } from './../../../config'
import {
  LivestreamTransformer,
  ProgramTransformer,
} from './../../../transformers'
import { mq, useTimeRange } from './../../../utility'
import cq from './../../../utility/coreQuery'
export interface LiveStreamContainerProps {
  source: string
  slug?: string
}

interface ChatState {
  username: string
  chatToken: string
}

const TIMES_FIRST_EVENT = {
  STARTING_SOON: '2021-10-06T12:30Z',
  STARTING: '2021-10-06T13:00Z',
  END: '2021-10-06T13:55Z',
  BLANK: '2021-10-06T15:00Z',
} as const

const getDiffWebcast = () => {
  const now = TimeUtil.getNowUtc()

  return TimeUtil.getUtc(TIMES_FIRST_EVENT.STARTING_SOON).diff(
    now,
    'millisecond'
  )
}

export const LiveStreamContainer: React.FC<LiveStreamContainerProps> = ({
  source,
  slug,
}) => {
  const postEventFirst = isPostEventFirst()
  const { t } = useTranslation('live')
  const videoRef = React.useRef<LiveStreamSimulatorRef>()

  const [streamData, setStreamData] = React.useState<LiveVideo[]>()
  const [loading, setLoading] = React.useState(true)
  const [isFiller, setIsFiller] = React.useState(false)
  const [currentSessionId, setCurrentSessionId] = React.useState('')
  const [nextSessionId, setNextSessionId] = React.useState('')
  const [entriesRaw, setEntriesRaw] = React.useState<
    ProgramEvent[] | undefined
  >()

  const [isChatActive, setIsChatActive] = React.useState(getDiffWebcast() <= 0)
  const isChatActiveTimeoutId = React.useRef<number>()

  React.useEffect(() => {
    const diff = getDiffWebcast()

    if (diff <= 0) {
      return
    }

    isChatActiveTimeoutId.current = window.setTimeout(() => {
      if (!isChatActive) {
        setIsChatActive(true)
      }
    }, getDiffWebcast())

    return () => {
      if (isChatActiveTimeoutId.current === undefined) {
        return
      }

      window.clearTimeout(isChatActiveTimeoutId.current)
    }
  })

  const [chatHeight, setChatHeight] = React.useState<number>(0)
  const ref = React.useRef<HTMLDivElement>(null)

  const [chatState, setChatState] = React.useState<ChatState>({
    chatToken: '',
    username: '',
  })

  const { history, send, pendingMessages } = useChatClient({
    room: 'cybersec_cys',
    token: chatState.chatToken,
    url: `https://chat.cuenect.de/chat`,
  })

  const [currentSession, setCurrentSession] = React.useState<ProgramEvent>()
  const [nextSession, setNextSession] = React.useState<ProgramEvent>()

  const displayTimezone = TimeUtil.getUsersTimezone() || eventConfig.timezone

  const getContent = async () => {
    const apiResponse: ProgramEvent[] = await ProgramService.getProgram(
      source,
      slug,
      true
    )
    setEntriesRaw(apiResponse)
    setStreamData(LivestreamTransformer.transform({ apiResponse }))
    setLoading(false)
  }

  const getOverlay = () => {
    return 'nextSoon'
  }

  const handleStateChange = (state: LivestreamSimulatorState) => {
    setIsFiller(
      state.stateId === StateId.FILLER || state.stateId === StateId.END
    )
    if (
      isLivestreamSimulatorState<LivestreamSimulatorStateType.LS_STATE_LOADING>(
        state,
        StateId.LOADING
      )
    ) {
      setCurrentSessionId(
        state.currentVideo.id &&
          useTimeRange(
            state.currentVideo.startUtc.format(),
            state.currentVideo.endUtc.format()
          )
          ? state.currentVideo.id
          : ''
      )
      setNextSessionId(
        state.nextVideos.length > 0 ? state.nextVideos[0].id : ''
      )
    }

    if (
      isLivestreamSimulatorState<LivestreamSimulatorStateType.LS_STATE_INITIAL>(
        state,
        StateId.INITIAL
      )
    ) {
      setNextSessionId(state.videos.length > 0 ? state.videos[0].id : '')
    }

    if (
      isLivestreamSimulatorState<LivestreamSimulatorStateType.LS_STATE_END>(
        state,
        StateId.END
      )
    ) {
      setCurrentSessionId('')
      setNextSessionId('')
    }
  }

  React.useEffect(() => {
    if (entriesRaw) {
      if (currentSessionId) {
        setCurrentSession(
          ProgramTransformer.getSessionById(currentSessionId, entriesRaw, {
            displayTimezone,
            showAppointment: true,
          })
        )
      }
      if (nextSessionId) {
        setNextSession(
          ProgramTransformer.getSessionById(nextSessionId, entriesRaw, {
            displayTimezone,
            showAppointment: true,
          })
        )
      }
    }
  }, [currentSessionId, nextSessionId, entriesRaw])

  const parseCustom = (session: IProgramEntry) => (
    <>
      {TimeUtil.convertToTimeZone(session.startUtc, displayTimezone).format(
        'HH:mm'
      )}
      {session.endUtc &&
        ` - ${TimeUtil.convertToTimeZone(
          session.endUtc,
          displayTimezone
        ).format('HH:mm')}`}
    </>
  )

  React.useEffect(() => {
    getContent()
  }, [])

  React.useEffect(() => {
    const setHeight = () => {
      if (!ref.current) {
        return
      }

      const { height } = ref.current.getBoundingClientRect()

      if (chatHeight !== height) {
        setChatHeight(height)
      }
    }

    setHeight()

    window.addEventListener('resize', setHeight)

    return () => window.removeEventListener('resize', setHeight)
  })

  React.useEffect(() => {
    ;(async () => {
      try {
        const token = await AuthService.getChatToken()

        const me = await AuthService.me()

        if (!me) {
          return
        }

        setChatState({
          username: `${me.firstname} ${me.lastname}`,
          chatToken: token,
        })
      } catch (e) {
        console.error(e)
      }
    })()
  }, [])

  const getViderOverlayText = () => {
    if (
      useTimeRange(TIMES_FIRST_EVENT.STARTING_SOON, TIMES_FIRST_EVENT.STARTING)
    ) {
      return parse(t('overlay.startingSoon'))
    }

    if (useTimeRange(TIMES_FIRST_EVENT.STARTING, TIMES_FIRST_EVENT.END)) {
      return t('overlay.starting')
    }

    if (useTimeRange(TIMES_FIRST_EVENT.END, TIMES_FIRST_EVENT.BLANK)) {
      return parse(t('overlay.end'))
    }

    return parse(t('overlay.before'))
  }

  const getVideoOverlay = () => {
    if (!isFiller) {
      return
    }

    return (
      <OveralyContainer>
        <Heading type="h4">{getViderOverlayText()}</Heading>
      </OveralyContainer>
    )
  }

  const transform = (m: ChatMessageSocket): ChatHistoryItem => {
    return {
      message: m.text,
      rejectedReason: m.rejectedReason,
      messageOwner: m.username,
    }
  }

  const messages = history.map(transform)
  const pending = pendingMessages.map(transform)

  return (
    <LiveStreamPlayerOuter>
      {!loading && (
        <>
          <LiveStreamPlayer>
            <LiveStreamPlayerRatio ref={ref}>
              <LiveStreamSimulator
                player={Video}
                ref={videoRef}
                startVideoType="autoplay_muted_topcontrols"
                videos={streamData}
                fadeOut
                loadingSpinnerDealyMs={4000}
                fadeToBlackMs={650}
                onStateChange={handleStateChange}
                videoProps={{
                  renderTopLeft: getVideoOverlay,
                  playerName: 'Live',
                  trackingTitle: currentSession?.title
                    ? `${currentSession?.title}||${JSON.stringify({
                        uid: currentSession.id,
                      })}`
                    : 'Filler Video',
                }}
                fillerVideo={'/assets/keyvisual.mp4'}
                endVideo={'/assets/keyvisual.mp4'}
              />
            </LiveStreamPlayerRatio>
          </LiveStreamPlayer>
          <LiveStreamTeaser>
            <SiemensChat
              fixedHeight={chatHeight}
              chatInputProps={{
                onSend: message =>
                  send({ text: message.message, username: chatState.username }),
                buttonProps: {
                  disabled: !isChatActive,
                },
              }}
              chatMessagesProps={{
                pendingMessages: pending,
                chatMessages: messages,
              }}
            ></SiemensChat>
          </LiveStreamTeaser>
        </>
      )}
      {/* <Spacer />
      {currentSessionId !== '' &&
        currentSession &&
        !currentSession.hidden &&
        (currentSession.endUtc.isAfter(TimeUtil.getNowUtc()) ||
          (nextSession &&
            nextSession.startUtc.isSame(TimeUtil.getNowUtc(), 'day'))) && (
          <SessionsContainer>
            <Heading underlined type="h4">
              {t('currentPresentation')}
            </Heading>
            <ProgramEntry
              toggleOpen={true}
              renderCustom={() => parseCustom(currentSession)}
              {...currentSession}
            />
          </SessionsContainer>
        )}
      <Spacer />
      {nextSessionId !== '' &&
        nextSession &&
        !nextSession.hidden &&
        nextSession.startUtc.isSame(TimeUtil.getNowUtc(), 'day') && (
          <SessionsContainer>
            <Heading underlined type="h5">
              {!currentSession ? t('firstPresentation') : t('nextPresentation')}
            </Heading>
            <ProgramEntry
              renderCustom={() => parseCustom(nextSession)}
              {...nextSession}
            />
          </SessionsContainer>
        )} */}
    </LiveStreamPlayerOuter>
  )
}

const Spacer = styled.div`
  grid-column: 1/37;
  margin: 15px 0;
  ${mq[3]} {
    margin: 45px 0;
  }
`

const OveralyContainer = styled.div({
  position: 'absolute',
  display: 'flex',
  height: '100%',
  width: '50%',
  top: 0,
  alignItems: 'center',
  padding: '2rem',
  boxSizing: 'border-box',
})

const LiveStreamContainerOuter = styled.div(() => ``)

const LiveStreamPlayerOuter = styled.div(
  ({ theme: { grid } }) => ` display: grid;
  grid-template-columns: repeat(${grid.columns},1fr);
  grid-template-rows: 1fr;
  margin-top:50px;
  z-index:2000;

  ${mq[3]} {
    margin-top:0;
  }
  `
)
const LiveStreamOverlay = styled(Heading)(
  ({ theme: { grid } }) => `
    position:absolute;
    left:20px;
    right:20px;
    top:20px;
    bottom:20px;
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    font-size:14px;

    ${mq[1]} {
      font-size:32px;
    }

  `
)

const LiveStreamPlayer = styled.div(
  ({ theme: { colors } }) => `
  position:relative;
   background:black;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  grid-column: 1/37;
  ${cq('UnmuteButtonIcon')}{
    svg{ fill:${colors.buttonText}}
  }


  ${mq[3]} {
    grid-column: 1/26;
  }
  `
)

const LiveStreamPlayerRatio = styled.div(
  ({ theme: { colors } }) => `
 position:absolute;
 top:0;
 left:0;
 width:100%;
 height:100%;
 & > div > div:first-of-type {
   height:100%;
 }
 ${cq('video-Black')}{
  background:${colors.bodyBackground};
}
  `
)

const LiveStreamTeaser = styled.div(
  ({ theme: { grid } }) => `
  display:flex;
  flex-direction:column;
  justify-content:center;
  grid-column: 1/37;
  ${mq[3]} {
    grid-column: 27/37;
  }
  button{
    text-align:center;
  }
  `
)

const SessionsContainer = styled.div(
  ({ theme: { grid } }) => `
  grid-column: 3/34;
  & > div{
    flex-direction: column;
    & > div:nth-of-type(1){
      padding-top:10px;
    }
    & > div:nth-of-type(2){
      padding-top:15px;
      border-bottom:none;
      & > div > div:nth-of-type(2){
        display:none;
      }
    }
  }
  `
)
