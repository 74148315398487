import { globalHistory } from '@reach/router'
import {
  AgendaIcs,
  AgendaMailto,
  LocalStorageUtil,
  TimeUtil,
  useFeatureFlags,
  Video,
  VideoProps,
  withVideoControls,
} from 'cuenect-web-core'
import dayjs from 'dayjs'
import { navigate } from 'gatsby-link'
import i18next from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgramModule } from '..'
import {
  AppointmentService,
  ProgramCategory,
  ProgramEvent,
  ProgramService,
} from '../../../api'
import { eventConfig } from '../../../config'
import { ProgramTransformer, RedirectProps } from '../../../transformers'
import { AnalyticsTransformer } from '../../../utility/analytics'
import {
  AgendaCta,
  LoadingIndicator,
  SieCalendar,
  SieEnvelope,
  SiePlay,
} from '../../atoms'
import { IProgramEntry } from '../../molecules/programEntry'
import {
  useBookmarksContext,
  useReservationContext,
  useTimezoneContext,
  useVodContext,
} from './../../../utility'

const TIMEZONE_CHECKED = 'usersTimezoneChecked'

export enum DateDisplay {
  DATE_DISPLAY_BUTTON = 'dateDisplayButton',
  DATE_DISPLAY_GROUP = 'dateDisplayGroup',
}

export interface ProgramContainerProps {
  source: string | any
  subdivision: string
  slug?: string
  hideTime?: boolean
  hideCta?: boolean
  onLoaded?(entries: IProgramEntry[][] | undefined): void
  filter?: string[] | undefined
  dateDisplay?: DateDisplay
  showReservations?: boolean
  showAppointment?: boolean
  showOnlyParticipations?: boolean
  minDate?: dayjs.Dayjs | null
  hideTestSession?: boolean
  staticSource?: boolean
  scroller?: any
  postEvent: boolean
}

const HIDE_ICS = '2021-10-06T14:00Z'

export const ProgramContainer: React.FC<ProgramContainerProps> = ({
  source,
  subdivision,
  slug,
  hideTime,
  hideCta,
  onLoaded,
  filter = [],
  dateDisplay = DateDisplay.DATE_DISPLAY_BUTTON,
  showReservations = false,
  showAppointment = false,
  showOnlyParticipations = false,
  minDate = null,
  hideTestSession = false,
  staticSource = false,
  scroller = null,
  postEvent = false,
}) => {
  const isStandalone = process.env.GATSBY_STANDALONE

  const hideIcs = TimeUtil.getNowUtc().isAfter(HIDE_ICS)

  const { t } = useTranslation(`program`)
  const { dispatch: openVod } = useVodContext()

  const {
    state: { timezoneChecked },
  } = useTimezoneContext()

  React.useEffect(() => {
    setIsChecked(!timezoneChecked)
  }, [timezoneChecked])

  const {
    state: { reservations, selectedDate: reserveSelectedDate },
    dispatch: dispatchReservation,
  } = useReservationContext()

  const {
    state: { bookmarks: stateBookmarks },
  } = useBookmarksContext()

  const { phase2, phase3 } = useFeatureFlags()

  const [isChecked, setIsChecked] = React.useState<boolean>(
    LocalStorageUtil.getString(TIMEZONE_CHECKED) === 'true'
  )

  const [agendaFilters, setAgendaFilters] = React.useState<string[]>([])
  const [agendaBookmarks, setAgendaBookmarks] = React.useState<boolean>()
  const [resetBookmarksToggle, setResetBookmarksToggle] = React.useState<
    boolean
  >(false)

  const [toggleSession, setToggleSession] = React.useState('')
  const [selectedDate, setSelectedDate] = React.useState(0)
  const [entriesRaw, setEntriesRaw] = React.useState<
    ProgramEvent[] | undefined
  >()
  const [participations, setParticipations] = React.useState<
    string[] | undefined
  >()
  const [rawParticipations, setRawParticipations] = React.useState()
  const [categories, setCategories] = React.useState<
    ProgramCategory[] | undefined
  >()

  const [rooms, setRooms] = React.useState<ProgramCategory[] | undefined>()

  const [entriesProps, setEntriesProps] = React.useState<IProgramEntry[][]>()
  const displayTimezone = isChecked
    ? TimeUtil.getUsersTimezone()
    : eventConfig.timezone

  const {
    state: { bookmarks },
    dispatch: dispatchBookmark,
  } = useBookmarksContext()

  const getContent = async () => {
    let apiResult: ProgramEvent[] = await ProgramService.getProgram(
      source,
      slug,
      false,
      staticSource
    )

    if (showAppointment) {
      // LOAD Appointments
      const appointmentRaw: ProgramEvent[] = await AppointmentService.getAppointments()
      if (appointmentRaw) {
        const appointmentResult: ProgramEvent[] = AppointmentService.parseForAgenda(
          appointmentRaw,
          {
            title: t('appointmentContent.title'),
            description: t('appointmentContent.description'),
          }
        )

        apiResult = [...apiResult, ...appointmentResult]
      }
    }

    if (filter.length) {
      apiResult = apiResult.filter(({ id }) => filter.includes(id))
    }

    setEntriesRaw(apiResult)
    setCategories(ProgramTransformer.getFilters(apiResult))
    setRooms(ProgramTransformer.getRooms(apiResult))
    if (!ProgramTransformer.hasBookmarkIds(bookmarks, apiResult)) {
      setResetBookmarksToggle(true)
    }
  }

  React.useEffect(() => {
    if (entriesRaw) {
      setResetBookmarksToggle(
        !ProgramTransformer.hasBookmarkIds(stateBookmarks, entriesRaw)
      )
    }
  }, [stateBookmarks])

  const getParticipations = async () => {
    const eventPart = await ProgramService.eventParticipation()

    setRawParticipations(eventPart)
    setParticipations(Object.keys(eventPart).map(k => k))
  }

  const parseCtaData = (input: string, session: IProgramEntry): string => {
    const { origin, pathname } = globalHistory.location
    const scheduleUrl = `${origin}${pathname}?uid=${session.id}`

    return input
      .replace('{title}', session.title)
      .replace('{url}', scheduleUrl)
      .replace('{regUrl}', eventConfig.regForm[i18next.language || 'en'])
      .replace('{date}', `${session.startUtc.format('LL')}`)
      .replace(
        '{time}',
        `${TimeUtil.convertToTimeZone(
          session.startUtc,
          eventConfig.timezone
        ).format('HH:mm')} ${eventConfig.timezoneShort}`
      )
      .replace(/<br\s*\/?>/gm, '%0D%0A')
      .replace(/&/gm, '%26')
  }

  const parseCta = (session: IProgramEntry) => {
    // console.log({
    //   phase3,
    //   videoUrl: session.videoUrl,
    //   canPlay: canPlayVod(session),
    //   hideIcs,
    //   session,
    // })

    return (
      <>
        {!showAppointment && (
          <AgendaMailto
            subject={parseCtaData(t('mail.subject'), session)}
            body={parseCtaData(t('mail.message'), session)}
            Icon={SieEnvelope}
            onClick={() => {
              AnalyticsTransformer.customEvent([
                'share-ptn',
                `ics||${JSON.stringify({ uid: session.id })}`,
              ])
            }}
          ></AgendaMailto>
        )}

        {!hideIcs && (
          <AgendaIcs
            Icon={SieCalendar}
            onClick={() => {
              AnalyticsTransformer.customEvent([
                'share-ptn',
                `mail||${JSON.stringify({ uid: session.id })}`,
              ])
            }}
            icsEvent={{
              subject: parseCtaData(t('ics.ICSTitle'), session),
              description: parseCtaData(t('ics.ICSNotes'), session),
              start: session.startUtc,
              end: session.endUtc,
              alarm: 30,
            }}
          />
        )}

        {phase3 && session.videoUrl && canPlayVod(session) && hideIcs && (
          <AgendaCta
            inverted
            className="inverted"
            onClick={() => {
              AnalyticsTransformer.customEvent([
                'share-ptn',
                `vod||${JSON.stringify({ uid: session.id })}`,
              ])
              openVod({
                type: 'OPEN_VOD',
                src: session.videoUrl,
                trackingTitle: `${session.title}||${JSON.stringify({
                  uid: session.id,
                })}`,
              })
            }}
          >
            <SiePlay color="#fff" size="2rem" />
          </AgendaCta>
        )}
      </>
    )
  }

  const canPlayVod = (session: IProgramEntry) =>
    session.endUtc
      .add(eventConfig.vodCanPlayOffset, 'minutes')
      .isBefore(TimeUtil.getNowUtc())

  const parseCustom = (session: IProgramEntry) => (
    <>
      {TimeUtil.convertToTimeZone(session.startUtc, displayTimezone).format(
        'HH:mm'
      )}
      {session.endUtc &&
        ` - ${TimeUtil.convertToTimeZone(
          session.endUtc,
          displayTimezone
        ).format('HH:mm')}`}
    </>
  )

  useEffect(() => {
    getContent()
    setParticipations(undefined)

    if (!isStandalone) {
      getParticipations()
    } else {
      setParticipations([])
    }
  }, [reservations])

  useEffect(() => {
    LocalStorageUtil.saveString(TIMEZONE_CHECKED, isChecked ? 'true' : 'false')

    if (entriesRaw && participations) {
      const entries = ProgramTransformer.transform({
        apiResponse: entriesRaw,
        displayTimezone,
        filters: agendaFilters,
        bookmarks,
        showOnlyBookmarks: agendaBookmarks || false,
        cta: hideCta ? () => <></> : parseCta,
        customContent: hideTime ? () => <></> : parseCustom,
        showReservations: phase2 || showReservations,
        showAppointment: showAppointment,
        participations,
        rawParticipations,
        showOnlyParticipations,
      })

      console.log('---<', entries)

      if (reserveSelectedDate === -1) {
        preselectDay(entries)
      } else {
        setSelectedDate(reserveSelectedDate)
      }

      const redirect:
        | RedirectProps
        | undefined = ProgramTransformer.handleRedirect(entries)
      redirectEntry(redirect)
      setEntriesProps(entries)

      if (onLoaded) {
        onLoaded(entries)
      }
    }
  }, [entriesRaw, participations, isChecked, agendaFilters, agendaBookmarks])

  React.useEffect(() => {
    dispatchReservation({
      type: 'RESET_DATESELECTED',
    })
  }, [])

  const preselectDay = (entries: IProgramEntry[][]) => {
    const dates = entries.map(
      //@ts-ignore need to fix
      (elem: IProgramEntry) => TimeUtil.getUtc(elem[0].startUtc)
    )
    const currentDate: dayjs.Dayjs = minDate
      ? minDate.isBefore(TimeUtil.getNowUtc())
        ? TimeUtil.getNowUtc()
        : minDate
      : TimeUtil.getNowUtc()

    // get index
    if (!postEvent) {
      dates.forEach((date, index) => {
        if (date.isSame(currentDate, 'date')) {
          setSelectedDate(index)
        }
      })
    }
  }

  const redirectEntry = (redirect: RedirectProps | undefined) => {
    if (!redirect) {
      return
    }
    if (redirect.isLive) {
      if (typeof window !== 'undefined') {
        return navigate(`/${i18next.language}/live`)
      }
    }

    setSelectedDate(redirect.selectedDate)
    setToggleSession(redirect.uid)
    if (
      typeof window !== 'undefined' &&
      document.getElementById(redirect.uid)
    ) {
      if (scroller) {
        scroller.scrollTop = document
          .getElementById(redirect.uid)
          .getBoundingClientRect().y
      }
      /*  document
        .getElementById(redirect.uid)
        ?.scrollIntoView({ behavior: 'smooth' }) */
    }

    if (redirect.openVod) {
      openVod({
        type: 'OPEN_VOD',
        src: redirect.videoUrl,
        trackingTitle: `${redirect.title}||${JSON.stringify({
          uid: redirect.uid,
        })}`,
      })
    }
  }

  return (
    <>
      {entriesProps ? (
        <ProgramModule
          header={{
            dates: entriesProps.map(
              //@ts-ignore need to fix
              (elem: IProgramEntry) => elem[0].startUtc
            ),
            userTimezone: TimeUtil.getUsersTimezone(),
            eventTimezone: eventConfig.timezone,
            selectedDate,
            onDateChange: idx => {
              setSelectedDate(idx)
              dispatchReservation({
                type: 'RESET_DATESELECTED',
              })
            },
            onTimezoneChange: () => setIsChecked(!isChecked),
            timezoneChecked: isChecked,
            categories,
            rooms,
            subdivision,
            hideBookmark: showAppointment,
            showFilter: !showAppointment && categories && categories.length > 0,
            dateDisplay,
            slug,
            onFilterChange: filters => setAgendaFilters(filters),
            onBookmarksChange: bookmarksActive =>
              setAgendaBookmarks(bookmarksActive),
            resetBookmarksToggle,
          }}
          entries={entriesProps}
          toggleSession={toggleSession}
          hideTestSession={hideTestSession}
        />
      ) : (
        <LoadingIndicator />
      )}
    </>
  )
}

const VideoWithControls: React.FC<VideoProps> = props =>
  withVideoControls(Video, props)
