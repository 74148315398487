import { TimeUtil } from 'cuenect-web-core'
import dayjs from 'dayjs'
import { ProgramEvent } from '../api/program'
import isBetween from 'dayjs/plugin/isBetween'
import React from 'react'
dayjs.extend(isBetween)
interface Languages {
  [language: string]: string
}
/* interface EventPhase {
  name: string
  time: Moment.moment
} */
interface WebinarPhase {
  id: number
  start: dayjs.Dayjs
  end: dayjs.Dayjs
  countdown: {
    start: dayjs.Dayjs
    end: dayjs.Dayjs
  }
}

interface EventConfig {
  utcOffset: number
  vodCanPlayOffset: number
  timezone: string
  timezoneShort: string
  countdownStart: dayjs.Dayjs
  countdownEnd: dayjs.Dayjs
  sources: Languages
  redirects: Languages
  regForm: Languages
  countdown?: string
  participationWarning: number
  conversationAvailable: number
  conversationCancable: number
  testSession: string
  postEvent: dayjs.Dayjs
  postEventFirst: dayjs.Dayjs
  postEventSecond: dayjs.Dayjs
  postEventAppointments: dayjs.Dayjs
  hidePage: dayjs.Dayjs
  currentWebinar: number
  phases: WebinarPhase[]
  /*   phases: Array<EventPhase>
   */
}
const hostname = typeof window !== 'undefined' ? window.location.hostname : ''

const apiUri =
  hostname !== 'localhost'
    ? '/api/v2'
    : 'https://cybersecurity-2021.siemens.cuenect.online/api/v2'

const eventPhases: WebinarPhase[] = [
  {
    id: 1,
    start: TimeUtil.getUtc('2021-09-06T14:00:00Z'),
    end: TimeUtil.getUtc('2021-10-06T13:00:00Z'),
    countdown: {
      start: TimeUtil.getUtc('2021-09-06T14:00:00Z'),
      end: TimeUtil.getUtc('2021-10-06T13:00:00Z'),
    },
  },
  {
    id: 2,
    start: TimeUtil.getUtc('2021-10-06T14:00:00Z'),
    end: TimeUtil.getUtc('2021-10-20T13:00:00Z'),
    countdown: {
      start: TimeUtil.getUtc('2021-10-06T14:00:00Z'),
      end: TimeUtil.getUtc('2021-10-20T13:00:00Z'),
    },
  },
  {
    id: 3,
    start: TimeUtil.getUtc('2021-10-20T15:00:00Z'),
    end: TimeUtil.getUtc('2021-11-03T14:00:00Z'),
    countdown: {
      start: TimeUtil.getUtc('2021-10-20T15:00:00Z'),
      end: TimeUtil.getUtc('2021-11-03T14:00:00Z'),
    },
  },
  {
    id: 4,
    start: TimeUtil.getUtc('2021-11-03T15:00:00Z'),
    end: TimeUtil.getUtc('2021-11-17T14:00:00Z'),
    countdown: {
      start: TimeUtil.getUtc('2021-11-03T15:00:00Z'),
      end: TimeUtil.getUtc('2021-11-17T14:00:00Z'),
    },
  },
]

const currentPhase = eventPhases.find(phase =>
  dayjs(TimeUtil.getNowUtc()).isBetween(phase.start, phase.end)
)

export const eventConfig: EventConfig = {
  utcOffset: 60,
  vodCanPlayOffset: 5,
  timezone: 'Europe/Berlin',
  timezoneShort: 'CEST',
  participationWarning: 5,

  postEvent: TimeUtil.getUtc('2021-10-25T15:15:00Z'),
  postEventFirst: TimeUtil.getUtc('2021-10-25T13:00:00Z'),
  postEventSecond: TimeUtil.getUtc('2021-10-25T13:30:00Z'),
  postEventAppointments: TimeUtil.getUtc('2021-10-25T13:00:00Z'),
  hidePage: TimeUtil.getUtc('2021-12-29T23:00:00Z'),
  countdown: '/countdown.mp4',
  conversationAvailable: 30,
  conversationCancable: 5,
  testSession: 'https://meet.virtualevent.siemens.com/testsession',
  sources: {
    de:
      process.env.GATSBY_LOCAL_DATA === 'true'
        ? '/mockData/events.json'
        : `${apiUri}/events.json`,
    en:
      process.env.GATSBY_LOCAL_DATA === 'true'
        ? '/mockData/events.json'
        : `${apiUri}/events.json`,
  },
  redirects: {
    de: '',
    en: '',
  },
  regForm: {
    de:
      'https://new.siemens.com/global/en/company/fairs-events/smart-buildings-connect.html#Registration',
    en:
      'https://new.siemens.com/global/en/company/fairs-events/smart-buildings-connect.html#Registration',
  },
  phases: eventPhases,
  currentWebinar: currentPhase?.id || 1,

  countdownStart:
    currentPhase?.countdown.start || TimeUtil.getUtc('2021-06-20T20:00:00Z'),
  countdownEnd:
    currentPhase?.countdown.end || TimeUtil.getUtc('2021-10-21T07:30:00Z'),
}

interface IDialogConfig {
  customerrequest: string[]
  customerproject: string[]
}

export const dialogConfig: IDialogConfig = {
  customerrequest: [
    'appointmentSales', // call_visit
    'specialRequest', // specific_information
    'noFollowUp', // no_follow_activity_agreed
    'noCustomerParticipation',
  ],
  customerproject: [
    'noProjectPlanned',
    'ongoingProject',
    'shortTerm',
    'midTerm',
    'longTerm',
  ],
}

export const isPostEventFirst = () =>
  TimeUtil.getNowUtc().isAfter(eventConfig.postEventFirst)
export const isPostEventSecond = () =>
  TimeUtil.getNowUtc().isAfter(eventConfig.postEventSecond)
export const isPostEvent = () =>
  TimeUtil.getNowUtc().isAfter(eventConfig.postEvent)
export const isPostEventAppointments = () =>
  TimeUtil.getNowUtc().isAfter(eventConfig.postEventAppointments)
export const isHidePage = () =>
  TimeUtil.getNowUtc().isAfter(eventConfig.hidePage)
export interface EventBreakEvent
  extends Pick<
    ProgramEvent,
    'title' | 'start' | 'end' | 'categories' | 'isBreak'
  > {}
interface EventBreaks {
  slug: string
  events: EventBreakEvent[]
}

export const eventBreaks: EventBreaks[] = [
  /* {
    slug: 'auditorium-de',
    events: [
      {
        isBreak:true,
        title: 'Mittagspause',
        start: '2021-04-12T08:15:00+00:00',
        end: '2021-04-12T08:40:00+00:00',
      },
    ],
  },
  {
    slug: 'auditorium-en',
    events: [
      {
        isBreak:true,
        title: 'Lunchbreak',
        start: '2021-04-12T08:15:00+00:00',
        end: '2021-04-12T08:40:00+00:00',
      },
    ],
  }, */
]
