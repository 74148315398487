import React from 'react'
import styled from '@emotion/styled'
interface CardProps {
  image?: string
}
export const Card: React.FC<CardProps> = ({ children, image }) => {
  return (
    <CardContainer>
      {image && (
        <CardImageContainer style={{ backgroundImage: `url(${image})` }} />
      )}
      <CardContentContainer>{children}</CardContentContainer>
    </CardContainer>
  )
}
const CardContainer = styled.div``
const CardImageContainer = styled.div`
  position: relative;
  background: black no-repeat center center / cover;
  overflow: hidden;
  height: 0;
  padding-top: 66%;
  margin-bottom: 10px;
`

const CardContentContainer = styled.div(
  ({ theme: { grid } }) => `
  margin-left: ${100 / grid.columns}vw;
`
)
