import styled from '@emotion/styled'
import { Button } from 'cuenect-web-core'
import React from 'react'
import { ExternalContent } from '../..'
import { ContentContainer } from '../../'
import { fairtouchConfig } from '../../../config'
import { AnalyticsTransformer, useModalContext } from '../../../utility'
import { Furioos, FurioosPlayer } from '../../atoms'

export const FurioosShowroom = React.forwardRef<FurioosPlayer, {}>((_, ref) => {
  const [handCursor, setHandCursor] = React.useState(false)

  // const lang = 'de'
  // const messageAction = data => {
  //   console.log('data', data)
  //   switch (data?.messageType) {
  //     case 'openLink':
  //       openLink(data)
  //       break
  //     case 'tracking':
  //       AnalyticsTransformer.customEvent(data.params)
  //       break
  //     case 'cursor':
  //       setHandCursor(data?.action === 'request')
  //       break

  //     default:
  //       break
  //   }
  // }

  // const openLink = data => {
  //   let url = ''
  //   switch (data?.linkType) {
  //     case 'url':
  //       url = data?.url
  //       break
  //     case 'agenda':
  //       url = `/${lang}/program/stage?noFrame=true`
  //       break
  //     case 'fairtouchWebplayer':
  //       url = `${
  //         fairtouchConfig.uri
  //       }/hm21-dialog/index.html?logo=false&langswitch=false&lccc=${
  //         lang === 'de' ? 'de-DE' : 'en-EN'
  //       }&path=${data?.uid}`
  //       break

  //     default:
  //       break
  //   }
  //   if (!url) {
  //     return
  //   }

  //   switch (data?.target) {
  //     case 'overlay':
  //       modalDispatch({
  //         type: 'OPEN_WITH_COMPONENT',
  //         state: {
  //           component: ExternalContent,
  //           params: {
  //             uri: url,
  //             fullscreen: true,
  //             onClose: () => {
  //               modalDispatch({
  //                 type: 'HIDE_MODAL',
  //               })
  //             },
  //           },
  //         },
  //       })
  //       break
  //     case 'blank':
  //       window.open(url)
  //       break
  //     default:
  //       break
  //   }
  // }

  return (
    <>
      {/* <ButtonDebug>
        <Button
          onClick={() =>
            setSdkMessage({
              messageType: 'goto',
              position: '0310',
            })
          }
        >
          Deeplink (0310)
        </Button>{' '}
        <Button
          onClick={() =>
            setSdkMessage({
              messageType: 'goto',
              naviId: 'START',
            })
          }
        >
          naviId (START)
        </Button>
        <Button
          onClick={() =>
            setSdkMessage({
              messageType: 'language',
              lccc: 'en-US',
            })
          }
        >
          Language (en-US)
        </Button>
        <Button
          onClick={() =>
            setSdkMessage({
              messageType: 'language',
              lccc: 'de-DE',
            })
          }
        >
          Language (de-DE)
        </Button>
      </ButtonDebug> */}
      <ContentContainer>
        <FurioosShowroomContainer handCursor={handCursor}>
          <Furioos playerId={String(process.env.GATSBY_PAYER_ID)} ref={ref} />
        </FurioosShowroomContainer>
      </ContentContainer>
    </>
  )
})

const ButtonDebug = styled.div`
  button {
    display: inline-block;
    margin-right: 10px;
  }
`
const FurioosShowroomContainer = styled.div<{ handCursor: boolean }>(
  ({ handCursor }) => `
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  position: relative;
  cursor:${handCursor ? 'pointer' : 'default'};
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
)
