import styled from '@emotion/styled'
import { LocalStorageUtil, useFeatureFlags } from 'cuenect-web-core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionEntry } from '../../'
import { GroupedProgramCategory, ProgramCategory } from '../../../api/program'
import { ProgramTransformer } from './../../../transformers'
import { useSlideInContext } from './../../../utility'
import { mq } from './../../../utility/breakpoint'
import { Button, Checkbox } from './../../atoms'

export const AGENDA_FILTER = 'agendaFilter'
export const AGENDA_SHOW_BOOKMARKS = 'agendaShowBookmarks'
interface AgendaFilterProps {
  categories: ProgramCategory[]
  rooms: ProgramCategory[]
  site: string
  subdivision: string
  onChange(filter: string[] | null, bookmarks: boolean): void
}

export const AgendaFilter: React.FC<AgendaFilterProps> = React.forwardRef(
  ({ categories, rooms, subdivision, onChange }, ref) => {
    React.useImperativeHandle(ref, () => ({
      closeSlideIn() {
        if (selectedFilter.length < 1) {
          saveFilter()
        }
      },
    }))

    const { phase2, roomFilters } = useFeatureFlags()

    const inputRef = categories.map(x => React.useRef(null))
    const { t } = useTranslation('agendaFilter')

    const [selectedFilter, setSelectedFilter] = useState<string[]>([])

    const [storageFilter, setStorageFilter] = useState<{
      [key: string]: string[]
    }>(() => {
      const ls =
        LocalStorageUtil.getObject<{ [key: string]: string[] }>(
          AGENDA_FILTER
        ) || {}
      setSelectedFilter(ls[subdivision] || [])

      return ls
    })

    const {
      state: { showSlideIn, component, params },
      dispatch: dispatchSlideIn,
    } = useSlideInContext()

    const [selectedBookmarks, setSelectedBookmarks] = useState<boolean>(
      LocalStorageUtil.getString(AGENDA_SHOW_BOOKMARKS) === 'true'
    )

    const changeFilter = (addDelete: boolean, slug: string) => {
      if (addDelete) {
        setSelectedFilter(oldArray => [...oldArray, slug])
      } else {
        setSelectedFilter(oldArray => oldArray.filter(item => item !== slug))
      }
    }

    const saveFilter = () => {
      const sf = { ...storageFilter }
      sf[subdivision] = selectedFilter

      LocalStorageUtil.saveData<{ [key: string]: string[] }>(AGENDA_FILTER, sf)
      onChange && onChange(selectedFilter, false)
      dispatchSlideIn({ type: 'HIDE_SLIDEIN' })
    }

    React.useEffect(() => {
      LocalStorageUtil.saveString(
        AGENDA_SHOW_BOOKMARKS,
        selectedBookmarks ? 'true' : 'false'
      )
      onChange && onChange(selectedFilter, selectedBookmarks)
    }, [selectedBookmarks])

    const groupedFilter: GroupedProgramCategory[] = ProgramTransformer.groupCategoryFilter(
      categories
    )

    console.log('categoriescategories', groupedFilter)
    const accordionData: AccordionEntry[] = []
    groupedFilter.forEach(({ type, filters }, index) => {
      accordionData.push([
        t(type),
        <div>
          {filters.map(({ slug, title }, i) => (
            <CheckboxItem key={i}>
              <Checkbox
                ref={inputRef[i]}
                name={slug}
                value={slug}
                label={title}
                defaultChecked={selectedFilter.includes(slug)}
                onClick={e => changeFilter(e.currentTarget.checked, slug)}
              />
            </CheckboxItem>
          ))}
        </div>,
        index === 0 && groupedFilter.length < 2,
      ])
    })

    return (
      <Container>
        <AccordionHolder>
          <Accordion data={accordionData} />
        </AccordionHolder>
        <ButtonHolder>
          <ResetButton onClick={() => saveFilter()}>{t('set')}</ResetButton>
          <ResetButton
            secondary
            disabled={selectedFilter.length < 1}
            onClick={() => {
              setSelectedFilter([])
              inputRef.map(e => e.current && (e.current.checked = false))
            }}
          >
            {t('reset')}
          </ResetButton>
        </ButtonHolder>
      </Container>
    )
  }
)

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  alignItems: 'stretch',
  height: '100%',
})

const ButtonHolder = styled.div({
  display: 'block',
  '& > button': {
    width: '100%',
  },

  [mq[3]]: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > button': {
      width: '45%',
    },
  },
})

const AccordionHolder = styled.div({
  flex: '1 1 auto',
  overflowY: 'auto',
  paddingRight: '20px',
  marginRight: '-20px',
  height: '0px',
})

const ResetButton = styled(Button)({
  width: '100%;',
  marginTop: '10px',
  [mq[3]]: { marginTop: '42px' },
  justifyContent: 'center',
})

const CheckboxItem = styled.div({
  margin: '20px 0',
})
